

import React from 'react';

import { Divider } from '@aws-amplify/ui-react';
import {useContext, useEffect, useState} from 'react';
import { ApiGet } from './Api'
import { useLocation } from 'react-router-dom';

import { LoadingFsm } from './const'

import { Modal, Container, Row, Col, Nav, Button, Image, Accordion, Card, CardGroup, ListGroup, Spinner, Alert} from 'react-bootstrap';

import DOMPurify from "dompurify";


///const mySafeHTML = DOMPurify.sanitize(myHTML);

const MINIMUM_FILE_LENGTH = 100; // fiddle index.html os loaded if file cannot be found so screen it out based on file length

export const Pages = (props: any) => {

    const [fsm, setFsm ] = useState(LoadingFsm.UNDEFINED);
    const [html, setHtml ] = useState("");





    const location = useLocation();



    //const path = ((props.hasOwnProperty('props')) && (props.props.hasOwnProperty('path'))) ? props.props.path :  location.pathname;
    //const path = (props.props.path !== 'undefined') ? props.props.path :  location.pathname;


    useEffect(() => {
        document.title = "Metrication Analysis";

        
        setFsm(LoadingFsm.UNDEFINED);

    }, [props]);


    useEffect(() => {

        const fetchData = async () => {

            //const propsPath:any = {...props};

            //const path = (propsPath.hasOwnProperty('path') && propsPath.props.hasOwnProperty('path')) ? propsPath.props.path : location.pathname;
        /*
            var path:string = location.pathname; //"/home"; // default
            switch (path)
            {
                case "":
                case "/":   
                    path = "/home"; 
                    break;
                    
                default:

            }
*/
            const propsCopy:any = {...props};

            var propsPath:string = "";
            propsPath = (propsCopy.hasOwnProperty('props') && propsCopy.props.hasOwnProperty('overidePath')) ? propsCopy.props.overidePath : propsCopy.props.path;

            console.debug('PATHS', propsCopy, propsPath, location.pathname);

            propsPath = (propsPath === '*') ? location.pathname : propsPath;
            
            //console.debug('PATHS', propsPath, location.pathname);


/*
            var path:string;  //"/home"; // default
            switch (location.pathname)
            {
                //case "":
                //case "/":   
                //case "/home":   
                //case "/signin":   
                //    path = "/home"; 
                //    break;

                //case "*":
                //    path = location.pathname;
                //    break;

                default:
                    path = propsPath;  

            }
                    */
            
            try {

            
                const response = await fetch(`/pages${propsPath}.html`);

               
                if (!response.ok) {
                   throw new Error(`Response status: ${response.status}`);
                }


                console.log('response',response );

                const raw = await response.text();

                if (raw.length <= MINIMUM_FILE_LENGTH) {
                    console.debug('FILE NOT FOUND raw');
                    throw new Error(`File Not Found`);
                }


                const sanitized = DOMPurify.sanitize(raw);


                //setHtml(await response.text());

             
                console.log('response html',raw.length, sanitized.length, raw);
                
                if (sanitized.length <= MINIMUM_FILE_LENGTH) {
                    console.debug('FILE NOT FOUND sanitised');
                    throw new Error(`File Not Found`);
                }



                setHtml(sanitized);

                setFsm(LoadingFsm.FOUND);

            //} catch (error:any)  {
            //    console.error(error.message);
            } catch {
                setHtml(`<h1>Error "/pages${propsPath}.html"  Not Found</h1>`);
                setFsm(LoadingFsm.ERROR);
            }
    
        };
          
        if (fsm === LoadingFsm.UNDEFINED) {
            setFsm(LoadingFsm.INITIALISING);
            fetchData();

        };
  
      }, [fsm]);

    console.log('fsm', fsm, props);

      //const test:any[] = [      <h1>test</h1>, <p>hello</p>, ]

      //const test:any[] =  [html]

    switch (fsm) {
        case LoadingFsm.FOUND:
        case LoadingFsm.ERROR:
            return (
                <>
                   <div dangerouslySetInnerHTML={{ __html: html }} />   
                </>
            )
        default:
            return null;    
    }


}

// <div dangerouslySetInnerHTML={{ __html: html }} />


export const PagesModal = (props:any) => {

    const handleClose = () => { props.setHidden()};  
    
    const pagesProps = {overidePath: props.url}


    return (
      <>
        <Modal show={true} onHide={handleClose}>
          <Modal.Header closeButton>
            {props.hasOwnProperty('title') && props.title}
          </Modal.Header>

          <Modal.Body><Pages props={pagesProps} /></Modal.Body>
         
        </Modal>
      </>
    );
  }
  