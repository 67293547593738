
import './App.css';

import { Layout } from './Layout';

const App = () => {
  
  //const table = useReactTable(options)

  console.log('APP');
  
  return (

    <>
       <Layout />
    </>
   
  );
}

export default App;

/*

    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.tsx</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>

*/