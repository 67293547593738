// pages/Home.js
import './App.css';

import React from "react";


import { Modal, Container, Row, Col, Nav, Button, Image, Accordion, Card, CardGroup, ListGroup, Spinner, Alert} from 'react-bootstrap';
import {useContext, useEffect, useState} from 'react';


import { LoadingFsm } from './const'

import { dnsQuery } from './dnsQuery'
import { Pages, PagesModal } from './Pages'

import { ApiPost } from './Api'
/*
import {ApiGet} from './Api';
import { CardHeaderLoading } from './CardServices';

import {SortAndIndex} from './Library';
*/

export const UrlImage = (props:any) => {
    const [fsm, setFsm ] = useState(LoadingFsm.UNDEFINED);
    const [domain, setDomain ] = useState("");

    //const [url, setUrl ] = useState("");      // not used now
    const [image, setImage ] = useState("");

    console.log('UrlImage', props, fsm);

    const domainNameRegex  = /[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,63}/
    const uriRegex    = /[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,63}\b([-a-zA-Z0-9@:%_\+.~#?&\/=]*)$/

    const documentRegex = /#document/

  

    useEffect(() => {
        
      console.log('props change', props);
 
      setDomain(props.url.match(domainNameRegex));

    }, [props]);


    useEffect(() => {

      const fetchData = async () => {
      const result = await dnsQuery({domain, type:'A'});

      if (result.hasOwnProperty('Answer')) {
          //setFsm(LoadingFsm.INITIALISING);
          setFsm(LoadingFsm.FOUND);
          props.domainOk(true);
      } else {
          setFsm(LoadingFsm.EMPTY);
          props.domainOk(false);
      }

    };
        
      console.log('domain change', domain);
      fetchData();

    }, [domain]);


    /*                    // NOT USED NOW
    useEffect(() => {

      const fetchData = async () => {

        const urlData:any = {
          "url":    url, 
          "wait":   1000, 
          "format": { width: 800, height: 600 }
        } 
        const post = await ApiPost('fetch', urlData, false); 

        console.log('SERVER CHECK-------->', post);


        if (post.length <= 0) {
          setFsm(LoadingFsm.ERROR);
        } else {
          setImage(post);
          setFsm(LoadingFsm.VALIDATED);
        }

      };
    
        
      console.log('url change', url);

      if (url.length > 0) {
        setFsm(LoadingFsm.REFRESH);
        fetchData();
      }

    }, [url]);   
      */ 
/*
    useEffect(() => {
      const fetchData = async () => {
          //console.log('dns', await dnsQuery({domain:props.url, type:'A'}));

          const domain:string = props.url.match(domainNameRegex);
      
          const result = await dnsQuery({domain, type:'A'});

          if (result.hasOwnProperty('Answer')) {
              setFsm(LoadingFsm.INITIALISING);
              props.domainOk(true);
          } else {
              setFsm(LoadingFsm.EMPTY);
              props.domainOk(false);
          }

      };

      //if (fsm === LoadingFsm.UNDEFINED) {
          fetchData();
      //}
  }, [props]);
*/

    //const onLoad = (e:any) => {

    //  setFsm(LoadingFsm.FOUND);
    //}

    const open = (url:string) => {
      window.open("https://www.geeksforgeeks.org",
        "", "noopener=1,height=400,width=200,top=100,left=100");
}





    console.log('***********fsm', fsm, image.length);
    switch (fsm) {

        /*case    LoadingFsm.INITIALISING: // state removed
          ///loading='lazy'
            return (
                <>
                  <h2>{props.title}</h2>
                  <div className={"d-flex justify-content-center"}  style={{width: 400}}>
                    <Spinner style={{width: 20, height: 20}} animation="border"></Spinner>
                  </div>              
                  <p><iframe id='i_frame'src={props.url} width={0} height={0} loading='lazy'onLoad={() => setFsm(LoadingFsm.FOUND)}></iframe></p>
              </>
            )      */
        case    LoadingFsm.EMPTY:
            return (
                <>
                  <h2>{props.title}</h2>
                  <div className="checkWarning">The domain:</div>
                  <div>{props.url.match(domainNameRegex)}</div>
                  <div className="checkWarning">has not been found.</div> 
                </>
            )


      
        case    LoadingFsm.ERROR:
        case    LoadingFsm.FOUND:
            return (
                <>
                  <h2>{props.title}</h2>
                  {/*<p><iframe id='i_frame' src={props.url} width={400} height={300} loading='lazy'onLoad={() => setFsm(LoadingFsm.FOUND)}></iframe></p>*/}
                  <p><a href={props.url} target="_blank">{props.url}</a></p>
                  {/*<p><Button onClick={() => setUrl(props.url)}>Server Link Check</Button></p>
                  <div className="checkWarning">if the image is not visible, please click the button above to view it in a new browser window.</div>*/}
                  <p>Click the above link to check you are ordering a report for the correct page.</p>
                 </>
            )
/*
        case    LoadingFsm.REFRESH:  
            return (
              <>
                <h2>{props.title}</h2>
                <div className={"d-flex justify-content-center"}  style={{width: 400}}>
                  <Spinner style={{width: 20, height: 20}} animation="border"></Spinner>
                </div>              
              </>
            )  

        case    LoadingFsm.VALIDATED:
          return (
            <>
              <h2>{props.title}</h2>
              <p><img width={400} height={300} src={`data:image/jpeg;base64, ${image}`}  /></p>
             </>
        )
        */
        case    LoadingFsm.UNDEFINED:
        default: 
            return null;
    }
  }


  /* 
  const UrlWindow = (url:string) => {
    const windowFeatures = "left=100,top=100,width=320,height=320";
const handle = window.open(
  "https://www.mozilla.org/",
  "mozillaWindow",
  windowFeatures,
);
  } 
/*  }
  let fbWindow = window.open(
      '',
      'facebook-window',
      'width=100,height=100,scrollbar=yes'
  );
  
  fbWindow!.opener = null;
  fbWindow!.location = 'https://facebook.com';
                
  }
*/
  /* 
const cardinfo = "d-flex justify-content-center"; //justify-center

const CardHeaderLoading = (props:any) => {

  
    return (
      <>
            <Card.Header className={cardinfo} style={{height: 50}}>{props.loading && <Spinner style={{width: 20, height: 20}} animation="border"></Spinner>}&nbsp;&nbsp;&nbsp;{props.title}</Card.Header>
      </>
    )
    
  };

  export {CardHeaderLoading}

/*

const textCenter = {
  textAlign: "center"
};

const textRight = {
  textAlign: "right"
};

const textLeft = {
  textAlign: "left"
};

const rowStyle = {
  // height: '100vw',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
 // marginBottom: '1rem'
  paddingTop: '1rem'
  //margin: 100 auto
};

const cardinfo = "d-flex justify-content-center"; //justify-center



class Website extends React.Component {
  constructor(props) {
    super(props);
    this.state = {loading: true, url: props.url};
  }

  static getDerivedStateFromProps(props, state) {
    
    if (props.url !== state.url) {
        return { loading: true, url: props.url };    
    }
    else
    {
      return null;
    }
  }

  render () {
  

  this.link = "https://"+this.props.url;
  

  return (
      <>
        <Card style={{width: 300}}>
          <CardHeaderLoading loading={this.state.loading} title={<a href={this.link}>{this.props.url}</a>}/>
          <Card.Body className={cardinfo} style={{width: 300, height: 300, padding:0}}><iframe src={this.link } width={300} height={300}  loading='lazy'onLoad={()=> this.setState({loading: false})}><p>Your browser does not support iframes.</p></iframe></Card.Body>
        </Card>
      </>

    )
  }
};



const SiteList = (props) => {

  const sites = props.data.map((record) => {
    return(<div key={record.domain}><Website url={record.domain}/></div>);
  })

  return (
    <>
      <Row className={cardinfo}> 
        {sites}
      </Row>
    </>
  )
  
};




class Epdsites extends React.Component {
  constructor() {
      super();
      //this.state = { data: [] };
  }
/*
  componentDidMount() {
    ApiGet('domain') // this is the API data fetched and later displayed by Status Information
      .then(res => this.setState({ data: SortAndIndex(res.Items) }));
  }
*//*
  render () {

    const textStyle = {
      textAlign: "center"
    };
  

    if (this.props.state.data.length === 0) { 

      return (
          <Container fluid="lg" className="content_container">
            <Row>
              <Col>
                <h1 style={textStyle}>Web Sites</h1>
                <div style={textStyle}><Spinner  animation="border" /></div>
              </Col>
            </Row>
          </Container>
      )
    }
    else {

      return (
          <Container fluid="lg" className="content_container">
            <Row>
              <Col>
                <h1 style={textStyle}>Web Sites</h1>
              </Col>
            </Row>
            <Row>
                <SiteList data={this.props.state.data}/>
            </Row>
          </Container>
      )
    }
  }
}

export {Epdsites, Website};
*/

/*

a href="../html-link.htm" target="popup" onclick="window.open('../html-link.htm','name','width=600,height=400')">Open page in new window</a>
<Card.Header className={cardinfo} style={{height: 100}}>&nbsp;&nbsp;&nbsp;<a href={props.url} target="popup">{props.url}</a></Card.Header>
*/