import React, { useCallback } from 'react';
import { host, env  } from './config'

import {useRef, useContext, useEffect, useState} from 'react';

import { Button, Col, Form, Row, InputGroup, Container, Tab, Tabs } from 'react-bootstrap';
import { ApiPost } from './Api'
import { LoadingFsm } from './const'

import { UrlImage } from './UrlImage'

import { PagesModal } from './Pages'

//import * as formik from 'formik';
import { Formik, useFormikContext } from 'formik';
import * as yup from 'yup';

//import ReCAPTCHA   from "react-google-recaptcha";

import { GoogleReCaptchaProvider, useGoogleReCaptcha, GoogleReCaptcha } from 'react-google-recaptcha-v3';

//const recaptchaRef = React.createRef();
//const recaptchaRef = useRef



export const EntryForm = (props:any) => {
  const key =  process.env.REACT_APP_RECAPTCHA_KEY!

  console.log('key', key);
  return (
    <GoogleReCaptchaProvider reCaptchaKey={key}>

        <EntryFormChild {...props}/>

   </GoogleReCaptchaProvider>
   )
}

export const EntryFormChild = (props:any) => {
  const [url, setUrl] = useState('');
  const [urlVerified, setUrlVerified] = useState(false);
  const [token, setToken] = useState('');
  const [terms, setTerms] = useState(false);
  //const reCaptchaRef = React.createRef();
  //const reCaptchaRef = useRef(null);
  //const { Formik } = formik;
  //const { values, submitForm } = useFormikContext();
/*
    useEffect(() => {
      console.log('Values Chane', values);

  }, [values]);
*/
/* THIS IS CAUSEING ERRORS SO DIABLING FOR V1
  check our https://medium.com/@vivekjoon1234/enhancing-security-and-user-experience-a-guide-to-implementing-google-recaptcha-v3-in-react-js-884b21bcbb79
 and https://stackoverflow.com/questions/53832882/react-and-recaptcha-v3
 and https://codesandbox.io/p/devbox/nextjs-google-recaptcha-v3-demo-803er0?file=%2Fpages%2Findex.js
 
    const { executeRecaptcha } = useGoogleReCaptcha();

    // Create an event handler so you can call the verification on button click event or form submit
    const handleReCaptchaVerify = useCallback(async () => {
    //const handleReCaptchaVerify = async () => {
      if (!executeRecaptcha) {
        console.log('Execute recaptcha not yet available');
        return;
      }
    

      const token = await executeRecaptcha('yourAction');
      // Do whatever you want with the token
      
      setToken(token);

      console.log('recaptcha token', token);

     //await getRecaptchaScore(token, "");


    }, [executeRecaptcha]);



    // You can use useEffect to trigger the verification as soon as the component being loaded
    useEffect(() => {
      handleReCaptchaVerify();
      
      return () => {
        // cancel the subscription
        setToken('');
      };

    }, [handleReCaptchaVerify]);

*/

  const checkUrl = (value:string) => {
    const host = value.trim();
    if (host.match(hostName)) {
      //const domain = value.match(domainName);
      //setUrl((domain === null) ? '' : domain[0]);
      setUrl(host);
    } else {
      setUrl('');
    }
  }


  const domainName  = /[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,63}/
  const hostName    = /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,63}\b([-a-zA-Z0-9@:%_\+.~#?&\/=]*)$/gm
  //const hostName = /^https?:\/\/[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm

  //const number = /^[0-9]{1,3}$/gm

  //const reCaptchaRef = React.createRef();


  const schema = yup.object().shape({
    tab: yup.string(),

    url:       yup.string().required().matches(hostName,'Name must be a valid URL name (e.g. https://example.com/landing)'),
    terms:     yup.boolean().required().oneOf([true],'Terms & Conditions must be accepted to proceed'),
  
   //   recaptcha: yup.string().required()
    //simple:       yup.string().when('tab', { is: 'simple', then(schema) { return schema.required() } }),
    //specialised:  yup.string().when('tab', { is: 'specialised', then(schema) { return schema.required() } }),
    //free:         yup.string().when('tab', { is: 'free', then(schema) { return schema.required().matches(number,'Name must be a number <= 999')} }),
    //free:         yup.string().required().matches(hostName,'Entry must be a number'),

  });

 
  console.debug('EntryForm', url, urlVerified)
  
  return (
 <>
       <Row>
           
        <Col sm={12} md={8}>
          <h2>Enter the URL of the landing page you would like analysed</h2>
          <Formik
            validationSchema={schema}
            validateOnBlur={false} 
            validateOnChange={false} 
       
            //validateOnMount={false}
            //onSubmit={console.log}
            initialValues={{
              url: 'https://',
              terms: false,
              //simple: '',
              //specialised: '',
              //free: '',
              //tab: 'simple',
              recaptcha: "",
              }}
           

            //onSubmit={(values:any) => {props.parameters.thread.urls = [values.url]; props.setRequest(props.parameters)}}  
            
            onSubmit={(values:any) => {
              // assume all parameters are verfied need only to check the image is verified and add captcha
              if (urlVerified) {
                console.log('SUBMITa');
                values.recaptcha = token;
                console.log('SUBMITb', values);
                props.setRequest(values);
              }
            }} 
          
            //onSubmit={handleReCaptchaVerify}
            //onSubmit={(values:any) => {defaultParameters.thread.urls = [values.url]; props.setRequest(defaultParameters)}}  

        
            //onSubmit={(values:any) => ( console.log('post', await ApiPost("openai", defaultData)) )}
            >

            {({ handleSubmit, handleChange, setFieldValue, values, touched, errors, isValid, isValidating, isSubmitting }) => (       
              <Form noValidate onSubmit={handleSubmit} >


                <Row>

                  <Form.Group as={Col} controlId="url">   
                    <Form.Label>Landing Page</Form.Label>
                      <InputGroup>      
                        <Form.Control
                          type="text"
                          placeholder=""
                          name="url"
                          value= {values.url}
                          //onChange={handleChange}
                          onChange={(e) => { handleChange(e); checkUrl(e.target.value) }}
                          isInvalid={!!errors.url}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.url?.toString()}
                        </Form.Control.Feedback>
                      </InputGroup>
                  </Form.Group>

                </Row>
                <br />
                <Row>

                  <Form.Group as={Col} controlId="terms"> 
                    <Form.Label>Accept Terms & Conditions</Form.Label>
                    &nbsp;<a className={"link"} onClick={() => setTerms(true)}>click to view terms</a>
                    <InputGroup>     
                      <Form.Check>
                        <Form.Check.Input 
                          type="checkbox"   
                          name="terms"     
                          onChange={handleChange}  
                          isInvalid={!!errors.terms}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.terms?.toString()}
                        </Form.Control.Feedback>
                      </Form.Check>
                    </InputGroup>              
                  </Form.Group>

                </Row>

  
   
                <Button type="submit" className="form_button">Submit your Order</Button>
        
                {(terms) && <PagesModal url={'/terms'} setHidden={() => setTerms(false)}/>}
  
                
              </Form>
            )}
          </Formik>

        </Col>
        <Col>
            {(url.length) > 0 && <UrlImage domainOk={setUrlVerified} title={'Visual Check of URL'} url={url}/>}
        </Col>
      </Row>
      <Row>
        <br />
      </Row>
        
      </>

  );
}
// <Button className="form_button" onClick={() => reCaptchaRef!.current!.execute()}>Test</Button>
/*


           <ReCAPTCHA
                ref={reCaptchaRef}
                sitekey="" //        
                onChange={(value) => {console.log('recaptcha', value); setFieldValue("recaptcha", value)}}
                //size="invisible"
              /> 


              */