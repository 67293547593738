import {createContext} from 'react';

import {ContentFsm} from './const'
//import {DmarcData, DmarcDataRecord} from './types'
//import {DmarcData} from './types'

export const UserContext = createContext({
    userStatus: "",
    setUserStatus: (status:string) => {},
});


export const MenuPathContext = createContext({
    lastMenuPath: "",
    setLastMenuPath: (path:string) => {},
});

/*
export const DmarcContext = createContext({
    dmarc: <DmarcData[]>[],
    setDmarc: (dmarc:DmarcData[]) => {},
});
*/
