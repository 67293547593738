import { Auth, API } from 'aws-amplify';
import { apiGateway } from './config';

const  ApiGet = async (path: string, auth:boolean=true) => {


    //return await API.get(apiName, path, myInit);
    try {
        if (auth) {
            apiGateway.apiInit.headers.Authorization = `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
        } else {
            apiGateway.apiInit.headers.Authorization = "";
        }
       
    
        console.log('apiGet', apiGateway.apiName,apiGateway.apiPath+path, apiGateway.apiInit);
    
        const get = await API.get(apiGateway.apiName, apiGateway.apiPath+path, apiGateway.apiInit);
    
        console.debug('ApiGet', JSON.stringify(get, null, 2));

        return get;

    } catch (e) {
        console.debug('Exit Catch Error', JSON.stringify(e, null, 2));
        return "";
    }
}


const  ApiPost = async (path: string, body:string, auth:boolean=true) => {

try {
    if (auth) {
        apiGateway.apiInit.headers.Authorization = `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
    } else {
        apiGateway.apiInit.headers.Authorization = "";
    }
   

    const init:any = apiGateway.apiInit;
    //init['body'] = data; //JSON.stringify(item.body);
    init['body'] = body; //JSON.stringify(item.body);

    console.debug('ApiPost apiGateway:', JSON.stringify(apiGateway,null,2));

    //return await API.post(parameters.apiName, parameters.apiPath+method, parameters.apiInit);

    const post =  await API.post(apiGateway.apiName, apiGateway.apiPath+path, init); 

    console.log('ApiPost', JSON.stringify(post, null, 2));

    return post;
    
}
catch (e) {
    console.debug('Exit Catch Error', JSON.stringify(e, null, 2));
    return "failed";
}
    
   // console.log('apiPost', apiGateway.apiName,apiGateway.apiPath+path, apiGateway.apiInit);

    //return await API.get(apiName, path, myInit);
    //return await API.post(apiGateway.apiName, apiGateway.apiPath+path, apiGateway.apiInit);
}


const ApiHeaders = async () => {
    
    //const headers = apiGateway.apiInit.headers;
    apiGateway.apiInit.headers.Authorization = `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`;

    //const headers = `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`;

    //return headers;
}


const  ApiGetNoWait = async (path: string) => {

    await ApiHeaders();

    //apiGateway.apiInit.headers["Authorization"] = headers;
  
    //return await API.get(apiName, path, myInit);
    return  API.get(apiGateway.apiName, apiGateway.apiPath+path, apiGateway.apiInit);
}

const  ApiGetArray = async (getArray: object[]) => {

    await ApiHeaders();

    const output = getArray.map(item => API.get(apiGateway.apiName, apiGateway.apiPath+item, apiGateway.apiInit));

    console.log('ApiGetArray', output);

    //Promise.all([ApiGetNoWait('domain'), ApiGetNoWait('domain'), ApiGetNoWait('domain'), ApiGetNoWait('backup')])


    //apiGateway.apiInit.headers["Authorization"] = headers;
  
    //return await API.get(apiName, path, myInit);
    return  Promise.all(output); //API.get(apiGateway.apiName, apiGateway.apiPath+path, apiGateway.apiInit);
}
/*

const  ApiCall = async (item: object) => {

    console.log('PATH', item);

    const path = item.resource;
    let response =null;

    switch (item.method) {
        case    'POST': const init = apiGateway.apiInit;
                        init.body = item.body; //JSON.stringify(item.body);
                        response = API.post(apiGateway.apiName, apiGateway.apiPath+path, init); console.log('POST', init); break;
        case    'GET' :
        default       : response = API.get(apiGateway.apiName, apiGateway.apiPath+path, apiGateway.apiInit); console.log('GET');
    }


    return response;
}


const  ApiCallArray = async (callArray) => {

    await ApiHeaders();

    console.log('ApiCallArray', Array.isArray(callArray));

    const output = Array.isArray(callArray) ? callArray.map(item => ApiCall(item)) : ApiCall(callArray); // single entry


    console.log('ApiCallArray', output);

    //Promise.all([ApiGetNoWait('domain'), ApiGetNoWait('domain'), ApiGetNoWait('domain'), ApiGetNoWait('backup')])


    //apiGateway.apiInit.headers["Authorization"] = headers;
  
    //return await API.get(apiName, path, myInit);
    return  Promise.all(output); //API.get(apiGateway.apiName, apiGateway.apiPath+path, apiGateway.apiInit);
}
*/

export {ApiGet, ApiGetNoWait, ApiGetArray, ApiHeaders, ApiPost};
  