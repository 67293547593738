const setConfig = (env:string) => {

    const dev = {

        Environment: 'development',
        Host: 'http://localhost:3000',

        Auth: {

            region: 'eu-west-2',
            userPoolId: 'eu-west-2_jovMGtVgn',
            userPoolWebClientId: '268r4a4kbuelqe4ojjs9tf71mu',

            API: {
                endpoints: [
                    {
                        name: 'llm-API-Gateway-dev',
                        //endpoint: "https://dev.metricaition.com"
                        endpoint: 'https://cvgmitcdg8.execute-api.eu-west-2.amazonaws.com',
                    }
                ]
            }
        },
        ApiGateway: {

            apiName: 'llm-API-Gateway-dev',
            apiPath: '/api/',

            apiInit: {
                headers: {
                    "Access-Control-Allow-Headers":     "*",
                    "Access-Control-Allow-Methods":     "*",
                    "Access-Control-Allow-Origin":      "*",
                    //"x-api-key":                        "alZyP6wcWB6u1gmCJ6aqi4lrqMOydBhF9ot3oM5N",
                     Authorization: ""
                }
            }
        }
    }

    const stage = { // not configed
 
        Environment: 'staging',
        Host: 'https://dev.metricaition.com',

        Auth: {
            region: 'eu-west-2',
            userPoolId: 'eu-west-2_jovMGtVgn',
            userPoolWebClientId: '6tn7m6g8o5cb38jdig17rjfa52',
            
            API: {
                endpoints: [
                    {
                        name: 'llm-API-Gateway-dev',
                        //endpoint: "https://dev.metricaition.com"
                        endpoint: ""
                    }
                ]
            }
        },
        ApiGateway: {  // stage uses the dev instance of api gateway

            apiName: 'llm-API-Gateway-dev',
            apiPath: '/api/', // the (dev) environment for api gateway is provided by cloudfront

            apiInit: {
                headers: {
                    "Access-Control-Allow-Headers":     "*",
                    "Access-Control-Allow-Methods":     "*",
                    "Access-Control-Allow-Origin":      "*",
                     Authorization: ""
                }
            }
        }
    }

    const prod = { // not configed

        Environment: '', // always kleep blank
        Host: 'https://metricaition.com',

        Auth: {

            region: 'eu-west-2',
            userPoolId: 'eu-west-2_mq3A2qt3n',
            userPoolWebClientId: '3fbirvq9hcoaq3qe5nuhdk3v2p',
           
            API: {
                endpoints: [
                    {
                        name: 'openai-API-Gateway',
                        //endpoint: "https://metricaition.com",
                         endpoint: ""
                    }
                ]
            }
        },
        ApiGateway: {

            apiName: 'openai-API-Gateway',
            apiPath: '/api/', // the (prod) environment for api gateway is provided by cloudfront

            apiInit: {
                headers: {
                    "Access-Control-Allow-Headers":     "*",
                    "Access-Control-Allow-Methods":     "*",
                    "Access-Control-Allow-Origin":      "*",
                    "x-api-key":                        "uKUHgDQ6r48kseGcGxvDh2ybIEAU3ulxaLb6emp9",
                    Authorization: ""
                }
            }
        }
    };



      switch (env) {
        case 'prod':
            return (prod);

        case 'stage':
            return (stage);

        case 'dev':
        default:
            return (dev);
    }
}


const env =  process.env.REACT_APP_STAGE == undefined ? "dev" : process.env.REACT_APP_STAGE.trim();



console.log('ORIGIN', window.location.origin);

const config = setConfig(env)


if (config.Auth.API.endpoints[0].endpoint.length === 0) {               // www version of domain treapped here
    config.Auth.API.endpoints[0].endpoint = window.location.origin; 
}


const awsconfig = config.Auth;
const environment = `${config.Environment} 1v0`;
const apiGateway = config.ApiGateway;
const logginglevel = 'VERBOSE';         // either: ERROR WARN INFO DEBUG VERBOSE
const host = config.Host;


export { awsconfig, environment, apiGateway, logginglevel, host, env };
