
import {useContext} from 'react';
import {Modal} from 'react-bootstrap';
import {ContentFsm} from './const';
import {UserContext} from './context'
import {Authenticator} from '@aws-amplify/ui-react';



export const SignIn = () => {
    const { userStatus, setUserStatus } = useContext(UserContext);

    const no = () => { 
        setUserStatus(ContentFsm.REVALIDATE);
    }

    return (
         <>

            <Modal   
                show={true} 
                onHide={no}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                size="sm"
            >

                <Authenticator 
                    loginMechanisms={['email']}
                    hideSignUp = {true}
                >
                </Authenticator>
        
        

            </Modal>

         </>
    );
}
