
export const dnsQuery = async (params: {domain:string, type:string}) => {
    console.log('dnsQuery', params);
    return (localDnsQuery(params.domain, params.type));
};

const localDnsQuery  = async (domain:string, type:string) => {

    console.log('LocalDnsQuery',domain);

    const query:string = `https://dns.google/resolve?name=${domain}&type=${type}`;

    let response;

    try {

        response = await fetch(query);
    
        response = await response.json();

    } catch {

        response = [{Question:[ {name: domain, type: type}]}]; // failed so fake a (non) result
    }


    console.log('response', response);

    return response;

};


