import React from 'react';
import { host, env  } from './config'

import {useContext, useEffect, useState} from 'react';

import { Button, Col, Form, Row, InputGroup, Container, Tab, Tabs, Spinner } from 'react-bootstrap';
import { ApiPost } from './Api'
import { LoadingFsm } from './const'


import { EntryForm } from './EntryForm'

import defaultParameters  from './data/defaultParameters.json'
import defaultPaymentDataDev from './data/defaultPaymentDataDev.json'
import defaultPaymentDataProd from './data/defaultPaymentDataProd.json'

import { SiMetrodeparis } from "react-icons/si";

// xxxx CHANGE LINE BELOW FOR test (launch) v order (stripe)
//export const Test = () => {
export const Order = (props: any) => {
  const [fsm, setFsm ] = useState(LoadingFsm.UNDEFINED);
  const [data, setData] = useState<any>({})
  //const [results, setResults] = useState<any>();

  const setFormResults = (results: any) => {

    console.log('setFormResults', results);

    const {url, terms, recaptcha} = results;

    const regEx  = /\/+$/ // trailing slashes regex
    
    const parameters:any = {...defaultParameters};

    parameters.thread.urls  = [url.trim().replace(regEx, '')];
    parameters.terms = terms;
    parameters.recaptcha = recaptcha;
    
    setData(parameters);
  }

  var paymentData:any;
  if (props.props.auth) {   // outh version of order is free i.e. 0 cost
    paymentData = (env === 'prod') ? {...defaultPaymentDataProd.LandingPage0000} : {...defaultPaymentDataDev.LandingPage0000};
  } else {                  // paid version (£17)
    paymentData = (env === 'prod') ? {...defaultPaymentDataProd.LandingPage1700} : {...defaultPaymentDataDev.LandingPage1700};
  }
     

 // const paymentData:any = {...defaultPaymentData};
  //const parameters:any  = {...defaultParameters};
    


  useEffect(() => {
      console.log('useEffect ReportForm');

      if (fsm === LoadingFsm.UNDEFINED) {
          setData({});
          setFsm(LoadingFsm.EMPTY);
      }
  }, [fsm]);
  

  useEffect(() => {
      
      const fetchData = async () => {
   
          //const post = await ApiPost("launch/", defaultData); //JSON.stringify(test));
          //console.log('data', data);

          
          var submissionData = { ...paymentData, host:host, body: data };
          submissionData.body.client = env;
         
          //console.log('submissionData', JSON.stringify(submissionData, null, 2));

          // xxxx CHANGE LINE BELOW FOR test (launch) v order (stripe)
          //const post = await ApiPost("launch/", submissionData); 
          //const post = await ApiPost("stripe/session/create/", submissionData); 
         
       
          const post = await ApiPost(props.props.api, submissionData, props.props.auth); 
      
          

          console.log('data', data);
          console.log('post', JSON.stringify(post, null, 2));

          if (post.hasOwnProperty('url')) {
            window.location.replace(post.url);
            // window.location.href = post.url;

            console.log('post', JSON.stringify(post, null, 2));
            //setResults(post);
            setFsm(LoadingFsm.FOUND);
          }
          else {
            // failed
            window.location.replace('/order-fail');
            setFsm(LoadingFsm.ERROR);
          }
        };

      if ((fsm === LoadingFsm.EMPTY) && (Object.keys(data).length !== 0)) { //} && Object.keys(data).length !== 0)  {
        setFsm(LoadingFsm.INITIALISING);
        fetchData();
      }
  }, [data]);
  
  console.log('redraw', fsm, props);


  switch (fsm) {
    case LoadingFsm.EMPTY:
    
      return (
        <>
            <h1>Metricaition™ Landing Page Report</h1>
            
            <br />
            <div className="center">
                <img width={200} src="/assets/images/examplereport.png" alt="Example Report" />
            </div>
            <br />
            {
              (props.props.auth) ? <h2 className={"red"}>THIS IS A FREE ORDER</h2> :
              <>
              <p>Complete the Form below and Submit it to Request a Report.  On submission you will be presented with a Payment Form</p>
              <p>Once a Payment of <s>&pound;27</s> <b>&pound;17 +VAT</b> is made the PDF Report will be started and, on completion a link will be emailed to your payment email address</p>
              </>
            }
            <EntryForm setRequest={setFormResults}/> 
            <div className="center">
                <img className="stripe" src="/assets/images/stripe.png" alt="Stripe Payments" />
            </div>   
        </>
      )
      break;
    case LoadingFsm.INITIALISING:
      return (
        <>
          <h1>Request a Landing Page Report</h1>
          <div className={"d-flex justify-content-center"}>
              <Spinner style={{width: 20, height: 20}} animation="border"></Spinner>
          </div>
        </>
      )
      break;
    default:
      return null;
  }
}

